import { getToken, setToken, removeToken } from '@/utils/auth'
import { convertRouter } from '@/utils/router'
import {login, logout, getInfo,getRouteList } from '@/api/login'
import {initRouter} from '@/router/routerConfig'


const user = {
  state: {
    token: getToken(),
    addRouters: [],
    rulesRouter:null || JSON.parse(localStorage.getItem("rulesRouter")),
    roles: [],
    supplierName: null,
    supplierCode: null,
    supplierId: null,
    areaCode:null,
    inventoryIs: null,//实时库存申报
    crossLibraryIs: null, // 供拣自助发货--批量送仓
    crossLibraryAdykIs: null, // 供拣自助发货--按单越库
    crossLibraryMdzsIs: null, // 供拣自助发货--门店直送
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_ROUTERS: (state, routers) => {
      const passData = state.rulesRouter.map(item => item.menuUrl)
      const disabledData = state.rulesRouter.filter(item => item.enable == 0).map(item => item.menuUrl)
      console.log(state.rulesRouter, 'state.rulesRouter')
      let crossLibraryArr = ['overAsn','supplierStoreDeliver', 'batchDelivery']
      let inventoryArr = ['supplier_inventory']
     
      routers.forEach(items => {
        if( !items.children) {
          return
        }
        
        items.children.map((item,index) => {
          if(passData.indexOf(item.name) > -1){
            item.hidden = false
          } else {
            item.hidden = true
          }
          if(disabledData.indexOf(item.name) > -1) {
            item.disabled = true
          } else {
            item.disabled = false
          }
         
          // if(!state.inventoryIs && inventoryArr.indexOf(item.name)> -1) {
          //   item.disabled = true
          // }
          // if(!state.crossLibraryIs && item.name == 'batchDelivery') {
          //   item.disabled = true
          // } 
          // if(!state.crossLibraryAdykIs && item.name == 'overAsn') {
          //   item.disabled = true
          // }
          // if(!state.crossLibraryMdzsIs  && item.name == 'supplierStoreDeliver') {
          //   item.disabled = true
          // }
        })
      })
      state.addRouters = routers
      state.routers = initRouter.concat(routers);
    },
    SET_ROUTERSIS:(state, data) => {
      state.rulesRouter = data
      localStorage.setItem("rulesRouter", JSON.stringify(data));
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_SUPPLIER: (state, data) => {
      state.supplierName = data.supplierName
      state.supplierCode = data.supplierCode
      state.supplierId = data.id
      state.inventoryIs = data.inventoryIs  //实时库存申报
      state.crossLibraryIs  = data.crossLibraryIs   // 供拣自助发货--批量送仓
      state.crossLibraryAdykIs  = data.crossLibraryAdykIs   // 供拣自助发货--按单越库
      state.crossLibraryMdzsIs  = data.crossLibraryMdzsIs   // 供拣自助发货--门店直送
    
      state.areaCode = data.deliverAreaCode
    }, 
  },
  actions: {
    invokerSetToken(context, token) {
      setToken(token)
      context.commit('SET_TOKEN', token)
    },
    // 获取用户信息
    GetInfo({commit, state}) {
      return new Promise((resolve, reject) => {
        getInfo(state.token).then(response => {
          const data = response.data
          if (data.roles && data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', data.roles)
            commit('SET_SUPPLIER', data.basSupplier)
          } else {
            reject('getInfo: roles must be a non-null array !')
          }
          const routers = convertRouter(data.routers, data.alias)
          commit('SET_ROUTERS', routers)
          resolve(response)
        }).catch(error => {
          // reject(error)
          console.log(error,'登陆报错信息')
        })
      })
    },
    getRoute({commit, state}) {
      return new Promise((resolve, reject) => {
        getRouteList({}).then(response => {
          const data = response.data
          commit('SET_ROUTERSIS', data)
          resolve(response)
        }).catch(error => {
          // reject(error)
          console.log(error,'登陆报错信息')
        })
      })
    },
    // 前端 登出
    FedLogOut({commit}) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    },
  }
}

export default user